import { Injectable } from "@angular/core";
import { SCRIPTS, COOKIE_DATA_DOMAIN_SCRIPT } from "src/environments/environment";

interface Scripts {
  name: string;
  src: string;
  dataDomainScript?: string;
}
export const ScriptStore: Scripts[] = [
  { name: "COOKIE", src: SCRIPTS.COOKIE, dataDomainScript: COOKIE_DATA_DOMAIN_SCRIPT },
  { name: "DYNATRACE", src: SCRIPTS.DYNATRACE },
  { name: "ATL", src: SCRIPTS.ATL },
  { name: "ATL_CONFIG", src: SCRIPTS.ATL_CONFIG }
];

@Injectable({
  providedIn: "root",
})
export class ScriptService {
  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
        dataDomainScript: script.dataDomainScript,
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      if (name in this.scripts && this.scripts[name]) {
        // resolve if already loaded
        if (this.scripts[name].loaded) {
          resolve({ script: name, loaded: true, status: "Already Loaded" });
        } else {
          // load script
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.nonce = "b590c2462003e59fa36213c6136211ad";
          if (this.scripts[name].dataDomainScript) {
            script.setAttribute('charset', 'UTF-8');
            script.setAttribute('data-language', 'de');
            script.setAttribute('data-domain-script', this.scripts[name].dataDomainScript);
          }
          script.src = this.scripts[name].src;
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: "Loaded" });
          };
          script.onerror = (error: any) =>
            resolve({ script: name, loaded: false, status: "Loaded" });
          document.getElementsByTagName("head")[0].appendChild(script);
        }
      } else {
        resolve({ script: 'Script not found', loaded: true, status: "Script not found" });
      }
    });
  }
}
